import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Loading.module.scss'

export const Loading = (props) => {
  const { variant = 'dots', size = 'sm', invert, ...rest } = props

  const dotClass = classNames(styles.dot, {
    [styles[size]]: size,
    [styles.invert]: invert,
  })

  const circleLoadingSize = {
    sm: '1rem',
    md: '2rem',
    xs: '.8rem',
  }[size]

  return (
    <div className={styles.loadingWrapper} {...rest}>
      {variant === 'dots' && (
        <ul className={styles.dotsList}>
          <li data-testid="dot" className={dotClass} />
          <li data-testid="dot" className={dotClass} />
          <li data-testid="dot" className={dotClass} />
        </ul>
      )}
      {variant === 'circle' && (
        <div data-testid="circle" className={styles.circleContent}>
          <div
            className={styles.circleLoading}
            style={{ width: circleLoadingSize, height: circleLoadingSize }}
          />
        </div>
      )}
      {variant === 'line' && <div className={styles.loaderLine} />}
    </div>
  )
}

Loading.size = { sm: 'sm', md: 'md', xs: 'xs' }
Loading.variant = { dots: 'dots', circle: 'circle', line: 'line' }

Loading.propTypes = {
  size: PropTypes.oneOf(Object.values(Loading.size)),
  variant: PropTypes.oneOf(Object.values(Loading.variant)),
  invert: PropTypes.bool,
}

export default Loading
