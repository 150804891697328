import { icons } from '.'

export const Icon = (props: IconProps): React.ReactElement => {
  const { size = '2.5rem', source = '', alt = '', onClick } = props

  return (
    <img
      className={props.className}
      style={{ height: `${size}`, width: `${size}`, overflow: 'hidden' }}
      src={source}
      alt={alt}
      onClick={onClick}
      role="presentation"
      data-testid="icon"
      draggable={false}
      id={props?.id}
    />
  )
}

interface IconProps {
  size: string
  source: string
  alt?: string
  className?: string
  onClick?: (e: { stopPropagation: () => void }) => void
  id?: string
}

Icon.icons = icons
