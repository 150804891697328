import Bugsnag from '@bugsnag/js'

import { logger } from 'lib/utils/logger'

const logError = (error, fnName, metaData = {}) => {
  logger('logError: ', fnName, metaData, error)
  Bugsnag.notify(error, (event) => {
    event.addMetadata('remarks', {
      ...window?.globals?._logData,
      ...metaData,
      function: fnName,
      logger: 'logError',
    })
  })
}

export default logError
