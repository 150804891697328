import { firebaseConfig as FIREBASE_DEVELOPMENT } from 'lib/config/firebase/dev'
import { firebaseConfig as FIREBASE_PRODUCTION } from 'lib/config/firebase/prod'

const getEnv = () => {
  try {
    if (['localhost', 'adhil.ngrok.dev']?.includes(window.location.hostname)) {
      return 'local'
    }
    return import.meta.env.MODE || 'development'
  } catch (e) {
    return 'development'
  }
}

const getMode = () => {
  try {
    return process?.env?.MODE || ''
  } catch (e) {
    return ''
  }
}

const ENV = getEnv()
const MODE = getMode()

const development = {
  ENV: ENV,
  BASE_API: 'https://web.gke.zoko.dev/v3',
  BASE_API_V4: 'https://web.gke.zoko.dev/v4',
  BASE_FLOW_API: 'https://zcom.zoko.dev',
  WEBHOOK_BASE_URL: 'https://webhook.zcom.zoko.dev/event/webhook',
  MQTT_URL: 'wss://mqtt.gke.zoko.dev/mqtt',
  FIREBASE: FIREBASE_DEVELOPMENT,
  UTM_SOURCE: 'zoko',
  UTM_MEDIUM: 'web_app',
  UTM_CAMPAIGN_WA: 'whatsapp',
  WA_URL_SHORTENER_API: 'https://test.gus.zoko.dev/api/v1',
  PAY_URL_SHORTENER_API: 'https://test.gus.zoko.dev/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: 'P3JTVCHYQ3F55QKC',
  BUGSNAG_API_KEY: '9998a9e9f8868f6565f05162e0066dc0',
  MIXPANEL: 'af9bfdbb6dc6f9e2f2e711fc5f6fce2b',
  STRIPE_API_KEY:
    'pk_test_51I9plQAeE7attOCzbDscWtPULZspmbnKrKV5eCrDdHQfjtJgyQcNjavZJYDqTyArC5nCQwOBoh7fFBzEE2akjF7600HgeDaHgI',
  SYNC_API_URL: 'https://test.management.zoko.dev',
  USER_PILOT: 'NX-60eg28i2',
  SHOPIFY_API_KEY: '88e0abf447b53546e5891f8696d4ef38',
  SHOPIFY_ACCESS_SCOPES:
    'read_script_tags,write_script_tags,read_orders,read_products,read_customers,read_fulfillments,read_product_listings,read_checkouts,write_checkouts,write_orders',
  FACEBOOK_APP_ID: '404784014777310',
  FACEBOOK_APP_SECRET: 'ad6c1b9ba0a1ecc751aaa79f0ef953e5',
  FACEBOOK_BASE_URL: 'https://graph.facebook.com/v17.0',
  FACEBOOK_SCOPES:
    'email,public_profile,pages_show_list,pages_messaging,business_management',
  INSTAGRAM_SCOPES:
    'public_profile,email,instagram_manage_messages,pages_manage_metadata,pages_messaging,instagram_basic,pages_read_engagement,pages_show_list,business_management',
  ADSMANAGER_SCOPES: `ads_read,pages_read_engagement`,
  FACEBOOK_PAGE_FIELDS:
    'picture{url},id,name,access_token,username,instagram_business_account{id,username,profile_picture_url,name}',
  ADSMANAGER_FIELDS: 'id,name,account_id',
  WHATSAPP_EXPIRY_HOURS: 24,
  MESSENGER_EXPIRY_HOURS: 168, //7 days
  DYNAMIC_LINK_API: 'https://web.gke.zoko.dev/v3/click?to=',
  WIDGET_SCRIPT_URL:
    'https://atlantis.zoko.dev/scripts/main.js?v=' + Date.now(),
  FLOWHIPPO_URL: '/zoko_flows',
  HEAVY_UTILS_URL: 'https://heavy-utils.zoko.dev',
  DIALOGUE_360_PARTNER_ID: 'QKlWYqPA',
  DIALOGUE_360_PARTNER_ID_INDIA: 'uAGAe8PA',
  DOPT_BLOCKS_API:
    'blocks-96096f76046f6b7946cdbbf244d2fbd421e6bfd85df59c9ce627b8be6e31f59c_MTI1MQ==',
  DOPT_USER_API_KEY:
    'users-df0f297d93179481360c54a777f8f0e705070076cee84c37d48023725631c834_MjY0Ng==',
  CONNECT_META_FB_ID: '404784014777310',
  CONNECT_META_CONFIG_ID: '971022575034531',
  COMMANDBAR_ID: '58cb0ade',
  GOOGLE_API_KEY: 'AIzaSyCJmFPpE8ZzeUbzt9xfez-L0imvnv2QDSM',
  GOOGLE_APP_ID: '571820199807',
  GOOGLE_CLIENT_ID:
    '571820199807-tij2d5dokflb5obrcuvk2gppm9lg2i2c.apps.googleusercontent.com',
  GOOGLE_SCOPES: [
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/drive.file',
  ],
}

const staging = {
  ...development,
  FACEBOOK_APP_ID: '404784014777310',
  FACEBOOK_APP_SECRET: 'ad6c1b9ba0a1ecc751aaa79f0ef953e5',
  BASE_API: 'https://stage.web.api.zoko.dev/v3',
  MQTT_URL: 'wss://mqtt.zoko.io/mqtt',
  FIREBASE: FIREBASE_PRODUCTION,
  URL_SHORTENER_API: 'https://wa.zoko.io/api/v1',
  PAY_URL_SHORTENER_API: 'https://pay.zoko.io/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: '6OMTZVRDWSRTB6KL',
  USER_PILOT: 'NX-60eg28i2',
  WHATSAPP_EXPIRY_HOURS: 24, // 1 day
  MESSENGER_EXPIRY_HOURS: 168, // 7 days
}

const PROD_FB_ID = '4504112716382182' // its same for prod and different for dev

const production = {
  ...development,
  BASE_API: 'https://web3.api.zoko.io/v3',
  BASE_API_V4: 'https://web-api.zoko.io/v4',
  BASE_FLOW_API: 'https://api.chatpowers.com',
  WEBHOOK_BASE_URL: 'https://hooks.chatpowers.com/event/webhook',
  MQTT_URL: 'wss://mqtt.zoko.io/mqtt',
  FIREBASE: FIREBASE_PRODUCTION,
  URL_SHORTENER_API: 'https://wa.zoko.io/api/v1',
  PAY_URL_SHORTENER_API: 'https://pay.zoko.io/api/v1',
  URL_SHORTENER_API_2FA_SECRET_KEY: '6OMTZVRDWSRTB6KL',
  MIXPANEL: '7659eae208e6d70830122e00195eda71',
  STRIPE_API_KEY:
    'pk_live_51I9plQAeE7attOCzo50v5aBqzkvJjyl4l67OxOPybp6oQzWeDXj3eSdfWyp55XPY5M1WvflyshVqowh2QSedEqnt00yyYtIDxo',
  SYNC_API_URL: 'https://management.zoko.io',
  USER_PILOT: 'NX-60eg28i2',
  SHOPIFY_API_KEY: 'a062193d60747a394630435afc6dfc74',
  FACEBOOK_APP_ID: PROD_FB_ID,
  FACEBOOK_APP_SECRET: 'c7769a9d9d6607a4b126766ac3822590',
  WHATSAPP_EXPIRY_HOURS: 24, // 1 day
  MESSENGER_EXPIRY_HOURS: 168, // 7 days,
  DYNAMIC_LINK_API: 'https://web3.api.zoko.io/v3/click?to=',
  WIDGET_SCRIPT_URL: 'https://atlantis.live.zoko.io/scripts/main.js',
  FLOWHIPPO_URL: 'https://app.flowhippo.com',
  DIALOGUE_360_PARTNER_ID: 'QKlWYqPA',
  DOPT_BLOCKS_API:
    'blocks-7af3e1c35d9606f635f068157eecd787e8c2fed3cda43ac32d6efad71e246c08_MjY2Mg==',
  DOPT_USER_API_KEY:
    'users-99a2d6619c891c40d4f7e561da56c0746513c80057477b6e255c7457fca3907a_MjY2Mw==',
  CONNECT_META_FB_ID: PROD_FB_ID,
  CONNECT_META_CONFIG_ID: '548992707333610',
  GOOGLE_API_KEY: 'AIzaSyAs3ypSaVi-k-zOGvvQ4RhGtb0txRgKPnc',
  GOOGLE_APP_ID: '153839500012',
  GOOGLE_CLIENT_ID:
    '153839500012-l10gdeer7vt2e3kn7u1qmoo9fnrlcp58.apps.googleusercontent.com',
}

const local = {
  ...development,
  // BASE_API: 'http://localhost:8080/v3',
  // BASE_API: 'https://43142c50a896.ngrok.app/v3',
  // BASE_API: 'https://abhai.in.ngrok.io/v3',
  // BASE_API: 'https://rabi-webapiv3.ngrok.io/v3',
  // BASE_API: 'https://11b45a30081b.ngrok.app/v3',
  // BASE_API: 'https://zokohook.ngrok.io/v3',
  // BASE_API: 'https://sachin.zoko.ngrok.io/v3',
  // BASE_FLOW_API: 'https://ac33f43ab41d.ngrok.app',
  WIDGET_SCRIPT_URL: 'http://localhost:3030/scripts/main.js?v=' + Date.now(), // atlantis server must be running
}

const configs = {
  development,
  staging,
  production,
  local,
}

const currentConfig = configs[ENV]

const config = {
  ENV: ENV,
  BASE_API: currentConfig.BASE_API,
  MQTT_URL: currentConfig.MQTT_URL,
  FIREBASE: currentConfig.FIREBASE,
  UTM_SOURCE: currentConfig.UTM_SOURCE,
  UTM_MEDIUM: currentConfig.UTM_MEDIUM,
  UTM_CAMPAIGN_WA: currentConfig.UTM_CAMPAIGN_WA,
  WA_URL_SHORTENER_API: currentConfig.WA_URL_SHORTENER_API,
  PAY_URL_SHORTENER_API: currentConfig.PAY_URL_SHORTENER_API,
  URL_SHORTENER_API_2FA_SECRET_KEY:
    currentConfig.URL_SHORTENER_API_2FA_SECRET_KEY,
  BUGSNAG_API_KEY: currentConfig.BUGSNAG_API_KEY,
  MIXPANEL: currentConfig.MIXPANEL,
  STRIPE_API_KEY: currentConfig.STRIPE_API_KEY,
  SYNC_API_URL: currentConfig.SYNC_API_URL,
  USER_PILOT: currentConfig.USER_PILOT,
  SHOPIFY_API_KEY: currentConfig.SHOPIFY_API_KEY,
  SHOPIFY_ACCESS_SCOPES: currentConfig.SHOPIFY_ACCESS_SCOPES,
  FACEBOOK_APP_ID: currentConfig.FACEBOOK_APP_ID,
  FACEBOOK_APP_SECRET: currentConfig.FACEBOOK_APP_SECRET,
  FACEBOOK_BASE_URL: currentConfig.FACEBOOK_BASE_URL,
  FACEBOOK_SCOPES: currentConfig.FACEBOOK_SCOPES,
  INSTAGRAM_SCOPES: currentConfig.INSTAGRAM_SCOPES,
  FACEBOOK_PAGE_FIELDS: currentConfig.FACEBOOK_PAGE_FIELDS,
  WHATSAPP_EXPIRY_HOURS: currentConfig.WHATSAPP_EXPIRY_HOURS,
  MESSENGER_EXPIRY_HOURS: currentConfig.MESSENGER_EXPIRY_HOURS,
  DYNAMIC_LINK_API: currentConfig.DYNAMIC_LINK_API,
  WIDGET_SCRIPT_URL: currentConfig.WIDGET_SCRIPT_URL,
  ADSMANAGER_SCOPES: currentConfig.ADSMANAGER_SCOPES,
  ADSMANAGER_FIELDS: currentConfig.ADSMANAGER_FIELDS,
  FLOWHIPPO_URL: currentConfig.FLOWHIPPO_URL,
  BASE_API_V4: currentConfig.BASE_API_V4,
  HEAVY_UTILS_URL: currentConfig.HEAVY_UTILS_URL,
  DIALOGUE_360_PARTNER_ID: currentConfig.DIALOGUE_360_PARTNER_ID,
  DIALOGUE_360_PARTNER_ID_INDIA: currentConfig.DIALOGUE_360_PARTNER_ID_INDIA,
  IS_PROD: ENV === 'production',
  IS_TEST_MODE: MODE === 'test',
  DOPT_BLOCKS_API: currentConfig.DOPT_BLOCKS_API,
  DOPT_USER_API_KEY: currentConfig.DOPT_USER_API_KEY,
  CONNECT_META_FB_ID: currentConfig.CONNECT_META_FB_ID,
  CONNECT_META_CONFIG_ID: currentConfig.CONNECT_META_CONFIG_ID,
  RESTRICT_ACCESS: currentConfig.RESTRICT_ACCESS,
  BASE_FLOW_API: currentConfig.BASE_FLOW_API,
  COMMANDBAR_ID: currentConfig.COMMANDBAR_ID,
  GOOGLE_CLIENT_ID: currentConfig.GOOGLE_CLIENT_ID,
  GOOGLE_SCOPES: currentConfig.GOOGLE_SCOPES,
  GOOGLE_API_KEY: currentConfig.GOOGLE_API_KEY,
  GOOGLE_APP_ID: currentConfig.GOOGLE_APP_ID,
  WEBHOOK_BASE_URL: currentConfig.WEBHOOK_BASE_URL,
}

export default config
