import PropTypes from 'prop-types'
import React from 'react'

import { BugsnagErrorBoundary } from 'lib/bugsnag'
import logError from 'lib/logError'
import trackEvent from 'lib/trackEvent'

import { Button } from '../Button'
import { Icon, icons } from '../Icon'
import { Text } from '../Text'
import styles from './ErrorBoundary.module.scss'

const WA_URL = `https://api.whatsapp.com/send?phone=${window?.globals?.businessNo}&text=Hi%20Zoko!%20Something%20went%20wrong%20in%20the%20Zoko%20dashboard.`

const _FallBackUI = ({ fallBackMessage }) => {
  const isWhiteLabelled = window?.globals?.isWhiteLabelled
  const text = `errorBoundary.${
    !isWhiteLabelled ? 'somethingWentWrong' : 'unknown'
  }`
  const _comp = () => {
    const Comp = (
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Icon source={icons.somethingWentWrong} size="var(--space-15)" />
        </div>
        <Text translate className={styles.heading}>
          {fallBackMessage || text}
        </Text>
        {!isWhiteLabelled ? (
          <>
            <Text translate className={styles.description}>
              errorBoundary.technicalStaffWorking
            </Text>
            <Button
              variant="text"
              onClick={() => window.open(WA_URL, '_blank').focus()}
            >
              <Text translate>errorBoundary.whatsappSupport</Text>
            </Button>
          </>
        ) : null}
        <Button onClick={() => window.location.reload()} variant="text">
          <Text translate>reload</Text>
        </Button>
      </div>
    )
    return Comp
  }
  return _comp
}

_FallBackUI.propTypes = {
  whiteLabelled: PropTypes.bool,
  fallBackMessage: PropTypes.string,
}

export const ErrorBoundary = ({ children, componentName, fallBackMessage }) => {
  const isWhiteLabelled = window?.globals?.isWhiteLabelled
  return (
    <BugsnagErrorBoundary
      FallbackComponent={_FallBackUI({ isWhiteLabelled, fallBackMessage })}
      onError={(event) => {
        logError(event, 'APP_CRASH', { componentName, event, isWhiteLabelled })
        window.console.error('APP_CRASH', componentName, event, isWhiteLabelled)
        trackEvent(
          trackEvent.modules.service,
          trackEvent.events.app_crash,
          event
        )
        event.severity = 'error'
        event.context = 'BugsnagErrorBoundary app_crash'
        return event.addMetadata('remarks', {
          componentName,
          logger: 'ErrorBoundary',
          event,
          isWhiteLabelled,
          page: window.location.href,
        })
      }}
    >
      {children}
    </BugsnagErrorBoundary>
  )
}

export default ErrorBoundary

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string.isRequired,
  fallBackMessage: PropTypes.string,
}
